.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: #cfd8dc;
}

.nav-bar ul {
  list-style: none;
  display: flex;
}

.nav-bar ul li {
  padding-left: 1rem;
}

.nav-bar a {
  text-decoration: none;
  color: #0d47a1;
}

.footer {
  display: flex;
  padding: 40px;
  font-size: 14px;
  color: #000;
  justify-content: center;
}

.productBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productBox li {
  text-align: center;
}

.productBox a {
  color: white;
  text-decoration: none;
}

.productBox a:hover {
  color: #61dafb;
}

.productLink:hover img {
  border: 2px solid #61dafb;
}

.productImage {
  border: 2px solid lightgray;
  border-radius: 20px;
  width: 250px;
}

.contactForm {
  margin: 0 auto;
  display: block;
  width: 400px;
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  opacity: 75%;
}

.contactForm label {
  display: block;
  width: 380px;
  padding: 5px;
  margin-top: 30px;
}

.contactForm input {
  display: block;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid gray;
}

.contactForm textarea {
  display: block;
  width: 300px;
  height: 100px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid gray;
}

.contactForm select {
  display: block;
  width: 400px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid gray;
}

.contactForm button {
  display: block;
  margin: 40px auto;
  width: 150px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid royalblue;
  color: royalblue;
  background-color: white;
  cursor: pointer;
}

.required {
  color: red
}

.success {
  color: green
}